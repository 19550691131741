import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../firebase';

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.logEvent('page_view', {
      page_path: location.pathname + location.search,
    });
  }, [location]);

  const trackSignUpConversion = () => {
    console.log('Tracking sign-up conversion'); // Add this line
    analytics.logEvent('sign_up');
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11465521326/p_YICKqP9tAZEK7xl9sq',
      });
    }
  };

  return { trackSignUpConversion };
};

export default useAnalytics;