import React from 'react';
import { Link } from 'react-router-dom';
//import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';

function Footer() {
  return (
    <footer className="bg-white text-gray-700 py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-primary">Skinmingle</h3>
            <p className="text-gray-600">Revolutionizing skincare with AI technology</p>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4 text-primary">Quick Links</h4>
            <ul className="space-y-2">
              <li><Link to="/about" className="hover:text-primary transition duration-300">About Us</Link></li>
              <li><Link to="/technology" className="hover:text-primary transition duration-300">Our Technology</Link></li>
              <li><a href="mailto:contact@skinmingle.com" className="hover:text-primary transition duration-300">Contact</a></li>
            </ul>
          </div>
          <div>
            <h4 className="text-xl font-semibold mb-4 text-primary">Legal</h4>
            <ul className="space-y-2">
              <li><a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f00f5ece-cf77-4ca4-9b65-6aabac3bc7c0" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition duration-300">Privacy Policy</a></li>
              <li><a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=725bee98-20cd-4227-bded-30cad73cd33f" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition duration-300">Terms of Service</a></li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-300 text-center">
          <p className="text-gray-600">&copy; {new Date().getFullYear()} Skinmingle. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;