import React, { useState, useEffect } from 'react';
import { ThumbsUp, ThumbsDown, MessageSquare, X } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const Modal = ({ children, isOpen, onClose }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className="bg-white rounded-lg p-6 max-w-md w-full mx-4"
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
        >
          {children}
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

const QuickFeedback = ({ message }) => {
  const [submitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState(true);
  const [error, setError] = useState(null);
  const [showFollowUp, setShowFollowUp] = useState(false);
  const [followUpResponses, setFollowUpResponses] = useState({});
  const { token } = useAuth();

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 5000); // Hide after 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleFeedback = async (isPositive) => {
    if (!message || !message.content) {
      setError('Cannot submit feedback: message is empty');
      console.error('Cannot submit feedback: message is empty');
      return;
    }

    if (!isPositive) {
      setShowFollowUp(true);
      return;
    }

    await submitFeedback(isPositive);
  };

  const submitFeedback = async (isPositive, followUpData = null) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/feedback/quick`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          is_positive: isPositive,
          message: {
            content: message.content,
            role: message.role,
          },
          follow_up_data: followUpData
        })
      });

      if (response.ok) {
        setSubmitted(true);
        setShowFollowUp(false);
        setTimeout(() => setVisible(false), 2000);
      } else {
        const errorData = await response.json();
        setError(`Failed to submit feedback: ${errorData.error || response.statusText}`);
        console.error('Failed to submit feedback:', errorData);
      }
    } catch (error) {
      setError(`Error submitting feedback: ${error.message}`);
      console.error('Error submitting feedback:', error);
    }
  };

  const handleFollowUpSubmit = () => {
    submitFeedback(false, followUpResponses);
  };

  const followUpQuestions = [
    { id: 'accuracy', question: 'Was the information inaccurate?', type: 'checkbox' },
    { id: 'relevance', question: 'Was the response not relevant to your question?', type: 'checkbox' },
    { id: 'clarity', question: 'Was the response unclear or confusing?', type: 'checkbox' },
    { id: 'completeness', question: 'Was the response incomplete?', type: 'checkbox' },
    { id: 'other', question: 'Any other feedback?', type: 'text' },
  ];

  if (!visible && !submitted && !showFollowUp) {
    return (
      <button 
        onClick={() => setVisible(true)} 
        className="text-xs text-gray-500 hover:text-primary transition-colors"
      >
        <MessageSquare size={12} className="inline mr-1" />
        Feedback
      </button>
    );
  }

  if (submitted) {
    return <p className="text-xs text-gray-500">Thank you for your feedback!</p>;
  }

  if (error) {
    return <p className="text-xs text-red-500">{error}</p>;
  }

  return (
    <>
      <div className="flex items-center space-x-2 mt-2 text-xs">
        <p className="text-gray-500">Was this response helpful?</p>
        <button onClick={() => handleFeedback(true)} className="p-1 hover:bg-gray-100 rounded">
          <ThumbsUp size={14} className="text-gray-400 hover:text-green-500" />
        </button>
        <button onClick={() => handleFeedback(false)} className="p-1 hover:bg-gray-100 rounded">
          <ThumbsDown size={14} className="text-gray-400 hover:text-red-500" />
        </button>
      </div>

      <Modal isOpen={showFollowUp} onClose={() => setShowFollowUp(false)}>
        <div className="relative">
          <button
            onClick={() => setShowFollowUp(false)}
            className="absolute top-0 right-0 text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
          <h3 className="text-lg font-semibold mb-4">Help us improve</h3>
          {followUpQuestions.map((q) => (
            <div key={q.id} className="mb-4">
              {q.type === 'checkbox' ? (
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    onChange={(e) => setFollowUpResponses({...followUpResponses, [q.id]: e.target.checked})}
                  />
                  <span>{q.question}</span>
                </label>
              ) : (
                <div>
                  <label className="block mb-1">{q.question}</label>
                  <textarea
                    className="w-full p-2 border rounded"
                    rows="3"
                    onChange={(e) => setFollowUpResponses({...followUpResponses, [q.id]: e.target.value})}
                  ></textarea>
                </div>
              )}
            </div>
          ))}
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setShowFollowUp(false)}
              className="text-gray-500 mr-4 hover:text-gray-700"
            >
              Cancel
            </button>
            <button
              onClick={handleFollowUpSubmit}
              className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QuickFeedback;