import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { auth } from '../../firebase';
import * as firebaseui from 'firebaseui';
import firebase from 'firebase/compat/app';
import 'firebaseui/dist/firebaseui.css';
import OnboardingModal from './OnboardingModal';
import LocationModal from './LocationModal';
import useAnalytics from '../../hooks/useAnalytics'; // Add this import

function LoginPage() {
  const navigate = useNavigate();
  const { login, setUser } = useAuth();
  const { trackSignUpConversion } = useAnalytics(); // Add this line
  const [uiShown, setUiShown] = useState(false);
  const [error, setError] = useState('');
  const [isOffline, setIsOffline] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLocationAllowed, setIsLocationAllowed] = useState(true);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkLocation = async () => {
      if (process.env.REACT_APP_CHECK_USER_LOCATION === 'true') {
        try {
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          // Commenting out the country check to allow all countries
          // if (data.country_code !== 'US') {
          //   setIsLocationAllowed(false);
          //   setShowLocationModal(true);
          // }
        } catch (error) {
          console.error('Error checking location:', error);
          setError('Unable to verify your location. Please try again later.');
        }
      }
    };

    checkLocation();
  }, []);

  useEffect(() => {
    const checkOnlineStatus = () => {
      setIsOffline(!navigator.onLine);
    };

    window.addEventListener('online', checkOnlineStatus);
    window.addEventListener('offline', checkOnlineStatus);

    return () => {
      window.removeEventListener('online', checkOnlineStatus);
      window.removeEventListener('offline', checkOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (isOffline || !isLocationAllowed) {
      return;
    }

    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(auth);
    }
    
    const uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            prompt: 'select_account'
          }
        },
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false
        }
      ],
      callbacks: {
        signInSuccessWithAuthResult: async (authResult) => {
          try {
            setLoading(true);
            await login(authResult.user);
            setCurrentUser(authResult.user);
            if (authResult.additionalUserInfo.isNewUser) {
              setShowOnboarding(true);
            } else {
              navigate('/home');
            }
          } catch (error) {
            console.error('Login error:', error);
            setError('Failed to log in. Please try again.');
          } finally {
            setLoading(false);
          }
          return false;
        },
      },
      tosUrl: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=725bee98-20cd-4227-bded-30cad73cd33f',
      privacyPolicyUrl: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=f00f5ece-cf77-4ca4-9b65-6aabac3bc7c0'
    };

    if (!uiShown && !isOffline && isLocationAllowed) {
      ui.start('#firebaseui-auth-container', uiConfig);
      setUiShown(true);
    }

    return () => {
      if (uiShown) {
        ui.reset();
      }
    };
  }, [login, navigate, uiShown, isOffline, isLocationAllowed]);

  const handleOnboardingComplete = async (onboardingData) => {
    try {
      setLoading(true);
      console.log("Completing onboarding");
      const token = await auth.currentUser.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/complete_onboarding`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(onboardingData)
      });

      if (!response.ok) {
        throw new Error('Failed to complete onboarding');
      }

      const data = await response.json();
      console.log("Onboarding completed successfully");
      setShowOnboarding(false);
      
      // Update the user state with the new data
      setCurrentUser(data.user);
      
      // Track sign-up conversion
      trackSignUpConversion(); // Add this line
      
      navigate('/home');
    } catch (error) {
      console.error('Error during onboarding:', error);
      setError('Failed to complete onboarding. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 via-purple-100 to-pink-100 flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-md w-full bg-white rounded-xl shadow-2xl overflow-hidden"
      >
        <div className="bg-gradient-to-r from-primary to-primary-dark p-6 text-white">
          <h2 className="text-3xl font-bold text-center mb-2">Welcome to Skinmingle</h2>
          <p className="text-center text-sm opacity-80">Log in or sign up to start your personalized skincare journey</p>
        </div>
        <div className="p-8">
          {error && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-red-500 text-center mb-4"
            >
              {error}
            </motion.p>
          )}
          {isOffline ? (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="text-center text-gray-600 mb-6"
            >
              You are currently offline. Please check your internet connection and try again.
            </motion.p>
          ) : (
            <>
              <div id="firebaseui-auth-container"></div>
              {!uiShown && isLocationAllowed && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-center text-gray-600 mt-4"
                >
                  Loading login options...
                </motion.p>
              )}
            </>
          )}
        </div>
      </motion.div>

      <AnimatePresence>
        {showOnboarding && (
          <OnboardingModal
            isOpen={showOnboarding}
            onComplete={handleOnboardingComplete}
          />
        )}
      </AnimatePresence>

      <LocationModal
        isOpen={showLocationModal}
        onClose={() => setShowLocationModal(false)}
      />
    </div>
  );
}

export default LoginPage;