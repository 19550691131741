import React, { useState } from 'react';
import { MessageSquarePlus, Star } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext'; // Adjust the import path as needed

const SuggestionBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const { token } = useAuth();

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/feedback/detailed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          rating,
          comment
        })
      });

      if (response.ok) {
        setComment('');
        setRating(0);
        setIsOpen(false);
        // Optionally, show a success message
      } else {
        console.error('Failed to submit feedback');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div className="fixed bottom-4 right-4">
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="bg-primary text-white p-3 rounded-full shadow-lg hover:bg-primary-dark"
        >
          <MessageSquarePlus size={24} />
        </button>
      )}
      {isOpen && (
        <div className="bg-white p-4 rounded-lg shadow-lg w-72">
          <h3 className="text-lg font-semibold mb-2">Share Your Thoughts</h3>
          <div className="flex justify-center mb-2">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                size={24}
                onClick={() => setRating(star)}
                className={`cursor-pointer ${star <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
              />
            ))}
          </div>
          <textarea
            className="w-full p-2 border rounded mb-2"
            rows="3"
            placeholder="Share your suggestion or feedback..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setIsOpen(false)}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark"
              disabled={rating === 0}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuggestionBox;